import React from "react";
import styled from "styled-components";
import AOS from "aos";
import "aos/dist/aos.css";

const Title = styled.div`
  text-align: start;
  margin: 1rem 0;
  font-size: 2rem;
  font-weight: 900;
  visibility: hidden;
  color: #f4ecd2;
  @media (max-width: 1050px) {
    visibility: unset;
  }
  /* transform: translateZ(0); */
`;
const Img = styled.img`
  width: 100%;
  display: flex;
  margin: auto;
  outline: 5px solid #e75b95;
  outline-offset: -10px;
`;
const ProjectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  place-items: center;
  transition: all 0.2s ease-out;

  :hover {
    ${Img} {
      outline-offset: -20px;
    }
    ${Title} {
      visibility: unset;
    }
  }
`;
const Project = ({ img, title, link }) => {
  React.useEffect(() => {
    AOS.init();
  });
  return (
    <ProjectWrapper data-aos="flip-down">
      <a href={link} alt={title} target="_blank" rel="noreferrer">
        <Img src={img} alt={title} />
      </a>
      <Title>
        <span>{title}</span>
      </Title>
    </ProjectWrapper>
  );
};
export default Project;
