import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Who from "../components/Who";

import SEO from "../components/seo";
import gorilla from "../images/gorilla.svg";
import Project from "../components/project";
import AOS from "aos";
import "aos/dist/aos.css";
import "../scss/gatstrap.scss";
import styled from "styled-components";

export const Panel = styled.div`
  min-height: 100vh;
  width: 100vw;
  text-align: center;

  align-items: center;
  justify-content: center;
  background-color: ${({ color }) => color};
  color: ${({ fontColor }) => fontColor};
`;

export const GorillaSection = styled(Panel)``;
export const GorillaCage = styled.div`
  display: flex;
  align-self: flex-start;
  align-items: center; /* for vertical align */
  display: flex;
  justify-content: center;
`;
export const Gorilla = styled.img`
  width: 55vh;
  color: #f4ecd2;
  :hover {
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }
  @keyframes shake {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
`;
export const BingBongTitle = styled.h2`
  ::before {
    content: "";
    display: block;
    width: 20px;
    border-top: 3px solid #e75b95;
    margin-bottom: 2vw;
  }
  font-weight: 900;
  font-size: 4rem;
`;

export const BigContainer = styled.div`
  display: block;
  width: 100%;
  padding: 8rem 2rem;
`;
export const ProjectsContent = styled.div`
  grid-template-columns: 1fr 1fr;
  @media (max-width: 1050px) {
    grid-template-columns: 1fr;
  }
  column-gap: 60px;
  row-gap: 68px;
  width: 100%;
  display: grid;

  padding: 3rem;
`;
export const ContactText = styled.div`
  width: 50%;
  margin: auto;
  font-size: 2rem;
  strong {
    font-weight: 900;
    color: #f4ecd2;
  }
  @media (max-width: 1050px) {
    width: 100%;
    font-size: 1.5rem;
  }
`;
export const ContactUs = styled.div`
  text-transform: uppercase;
  font-weight: 900;
  cursor: pointer;
  padding: 1.2rem;
  a {
    color: #e75b95;
    :hover {
      color: #e75b95;
    }
  }

  img {
    width: 10%;
  }
`;

const IndexPage = (props) => {
  React.useEffect(() => {
    AOS.init();
  });
  return (
    <Layout>
      <SEO title="bingbong" />
      <div>
        <GorillaSection color={"#231f20"} fontColor={"#e75b95"} id="header">
          <GorillaCage>
            <Gorilla src={gorilla} alt="the big bingbong gorilla" />
          </GorillaCage>
        </GorillaSection>
        <Panel color={"#231f20"} fontColor={"#e75b95"} id="who">
          <Who />
        </Panel>
        <Panel color={"#231f20"} fontColor={"#e75b95"} id="work">
          <BingBongTitle data-aos="fade-up-right">Our Work</BingBongTitle>

          <ProjectsContent>
            <Project
              img={"/lahfa.jpg"}
              title="Lahfa"
              link="https://www.imdb.com/title/tt5674090/"
            />
            <Project
              img={"/shabady.jpg"}
              title="Shab 3ady"
              link="https://www.youtube.com/watch?v=lqpwKM7gdhw"
            />
            <Project
              img={"/snl.jpg"}
              title="SNL"
              link="https://www.imdb.com/title/tt5533060/"
            />
            <Project
              img={"/movie.jpg"}
              title="Farce"
              link="https://www.imdb.com/title/tt8888056/"
            />
          </ProjectsContent>
        </Panel>
        <Panel color={"#231f20"} fontColor={"#e75b95"} id="contact">
          <BigContainer>
            <ContactText>
              If you are a storyteller with any level of experience, <br />
              <strong>BingBong is your new playground!</strong>
              <br />
              <br />
              If you are a production house of any size, <br />
              <strong>BingBong is your new favorite creative partner.</strong>
            </ContactText>
            <ContactUs>
              <a href="mailto:info@bingbongstudios.com ">
                <Gorilla src={gorilla} />
                Email Us Now
              </a>
            </ContactUs>
          </BigContainer>
        </Panel>
      </div>
    </Layout>
  );
};

export default IndexPage;
export const siteQuery = graphql`
  query SiteQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
