import styled from "styled-components";
export const Content = styled.div`
  width: 100vw;

  position: relative;
  overflow: visible;

  margin: auto;

  display: flex;
  gap: 20px;
  padding: 3rem;
  justify-content: space-between;
`;
export const SectionName = styled.div`
  ::before {
    content: "";
    display: block;
    width: 20px;
    border-top: 3px solid #e75b95;
    margin-bottom: 2vw;
  }
  font-size: 1rem;
  font-weight: 900;
  @media (max-width: 1050px) {
    display: none;
  }
`;
export const SectionText = styled.section`
  display: flex;
  width: 60%;
  flex-direction: column;

  text-align: start;
  @media (max-width: 1050px) {
    width: 100%;
  }
  p {
    font-size: 1.25rem;
  }
  strong {
    font-weight: 900;
    color: #f4ecd2;
  }
`;

export const ListsContainer = styled.section`
  grid-template-columns: 1fr 1fr 1fr;
  text-align: left;
  place-items: center;
  @media (max-width: 1050px) {
    grid-template-columns: 1fr;
  }
  row-gap: 20px;
  padding: 3rem 1rem;
  /* grid-template-rows: 80px auto 80px; */
  /* row-gap: 15px; */
  width: 100%;
  display: grid;
`;

export const ListTitle = styled.div`
  font-weight: 800;
  font-size: 1.2rem;
`;
