import React from "react";

import AOS from "aos";
import "aos/dist/aos.css";
import "../../scss/gatstrap.scss";
import { BingBongTitle, BigContainer } from "../../pages/index";
import {
  Content,
  SectionName,
  SectionText,
  ListsContainer,
  ListTitle,
} from "./style";
const Who = () => {
  React.useEffect(() => {
    AOS.init();
  });
  return (
    <>
      <Content data-aos="fade-up">
        <SectionName>About Us</SectionName>
        <SectionText>
          <BingBongTitle>BingBong Studios</BingBongTitle>
          <p>
            <strong>BingBong</strong> is a creative writing agency with a
            mission to connect exceptional writing talents to the media
            industry. <strong>Our Vision</strong> is to build a network of
            creative storytellers, leverage our collective experiences on the
            creative and business fronts so that their high quality relevant
            work could make it to the screens for millions to enjoy.
          </p>
          <br />
          <p>
            We are in the business of <strong>Creating Classics</strong>,
            specialized in the craft of storytelling and taking our pride in
            having a stellar network of
            <strong> global and local production partners </strong>
            that actualize & monetize our creations.
          </p>
        </SectionText>
      </Content>

      <BigContainer data-aos="fade-up-right">
        <BingBongTitle>Our Services</BingBongTitle>
        <ListsContainer>
          <ListTitle>Logline</ListTitle>
          <ListTitle>Concept & World</ListTitle>
          <ListTitle>Synopsis</ListTitle>
          <ListTitle>Show Cross</ListTitle>
          <ListTitle>Character Profiles</ListTitle>
          <ListTitle>Character Arcs</ListTitle>
          <ListTitle>Seasons Map</ListTitle>
          <ListTitle>Pilot Design</ListTitle>
          <ListTitle>Season Beats</ListTitle>
          <ListTitle>Formats</ListTitle>
          <ListTitle>Episodes Scripts</ListTitle>
          <ListTitle>Script Doctoring</ListTitle>
        </ListsContainer>
      </BigContainer>
    </>
  );
};

export default Who;
